<template>
  <v-container fluid class="pa-0">
    <v-card flat class="pa-0">
      <v-img
        class="demo-light-bg ml-n1"
        :class="{ 'demo-dark-bg': theme }"
        :src="theme ? backgroundDark : backgroundLight"
      >
      <v-row class="mt-15">
        <v-col></v-col>
        <v-col cols=8>
          <v-card color="mt-15 text-center  transparent white--text" flat>
          <v-card-title class="text-h3 font-weight-bold">
            <v-spacer></v-spacer>
            <span>About Us</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="white--text">
            <div class="text-h6 font-weight-regular" >
              Learn more about the team and future vision for haq.ai
            </div>
          </v-card-text>
          <v-card-text>
            <v-btn :color="theme ? '#1E2633' : 'white'" class="pa-5 rounded-lg" @click="$router.push('/auth/register')">CREATE ACCOUNT</v-btn>
          </v-card-text>

          <v-card-text>
            <span class="white--text">Find us on</span>
            <div class="d-flex justify-center mt-5">
              <a target="__blank" href=" https://discord.gg/8y9ZPGm3Wq" class="white--text mt-1"><i class="fab fa-discord fa-lg"></i> </a>
              <a target="__blank" href="https://www.linkedin.com/company/74531880" class="ml-2 white--text mt-1"> <i class="fab fa-linkedin fa-lg"></i> </a>
              <a target="__blank" href="https://github.com/The-Eigensolvers/" class="ml-2"><v-icon color="white">mdi-github</v-icon></a>
              <a target="__blank" href=" https://www.youtube.com/channel/UCYAKRX39svr_h3o_TEDbxQg" class="ml-2"><v-icon color="white">mdi-youtube</v-icon></a>
            </div>
          </v-card-text>
        </v-card>
        </v-col>
        <v-col></v-col>
      </v-row>
      
      </v-img>
      <v-card class="mt-n10 pa-0" min-height="40" flat>
      </v-card>
      
       <!-- after baner  -->
      <Services class="" />
      <!-- after banner  -->

      <!-- team  -->
      <!-- <Team /> -->
      <!-- team  -->

     <!-- section  -->
      <v-container class="mt-5">
        <div class="d-flex justify-center mx-15">
          <v-card
           flat
           >
            <v-card-title>
              <v-spacer></v-spacer>
                <div class="text-center">
                  <div class="blue--text font-weight-bold text-h4">100</div>
                  <span class="text-disabled text-body">Problems</span>
                </div>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="text-center">
              <span>Of “high-performing” level are led by a certified project manager</span>
            </v-card-text>

           </v-card>

           <v-card
           flat
           >
            <v-card-title>
              <v-spacer></v-spacer>
                <div class="text-center">
                  <div class="blue--text font-weight-bold text-h4">2</div>
                  <span class="text-disabled text-body">Challenges</span>
                </div>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="text-center">
              <span>That meets quality standards required by our users</span>
            </v-card-text>

           </v-card>

            <v-card
           flat
           >
            <v-card-title>
              <v-spacer></v-spacer>
                <div class="text-center">
                  <div class="blue--text font-weight-bold text-h4">24/7</div>
                  <span class="text-disabled text-body">Support</span>
                </div>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="text-center">
              <span>On our global community on Discord</span>
            </v-card-text>

           </v-card>
        </div>
      </v-container>
      <!-- section  -->
    </v-card>
  </v-container>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'About',
  components: {
    Team: () => import('./components/team'),
    Services: () => import('./components/services'),
  },
  mixins: [colorMxn],
  data() {
    return {
      backgroundDark: '',
      backgroundLight: '',
    }
  },
  mounted() {
    this.backgroundDark = require('@/assets/images/background-dark.svg');
    this.backgroundLight = require('@/assets/images/background-light.svg');
  },

}
</script>

<style>
.demo-wrap {
  overflow: hidden;
  position: relative;
}

.demo-light-bg {
  background-color:#6034BD;
  opacity: 0.9;
}

.demo-dark-bg {
  background-color:#141727;
  opacity: 0.9;
}

.team-light-bg {
  background-image:linear-gradient(#3A416F, #141727);
}

.team-dark-bg {
  background-image:linear-gradient(#1E2633, #C1AFE9);
}
</style>